@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'NanumGothic';
  font-style: normal;
  src: url('../fonts/NanumGothic.otf') format('otf');
  font-weight: 400;
}

@font-face {
  font-family: 'NanumGothic';
  font-style: normal;
  src: url('../fonts/NanumGothicBold.otf') format('otf');
  font-weight: 600;
}

@font-face {
  font-family: 'NanumGothic';
  font-style: normal;
  src: url('../fonts/NanumGothicExtraBold.otf') format('otf');
  font-weight: 800;
}

@font-face {
  font-family: 'NanumGothic';
  font-style: normal;
  src: url('../fonts/NanumGothicLight.otf') format('otf');
  font-weight: 200;
}

html {
  @apply h-[100%]
}

body {
  @apply block h-[100%] overflow-auto bg-[#fafaf9] border-solid border-[0.5px] border-[#f3f3f3]
}

main {
  @apply block min-w-[1036px] h-[100%]
}

.body {
  @apply px-[40px] pt-[35px] pb-[90px]
}

th {
  @apply font-[600] border-r-[1px] border-[#b7b7b7]
}

th.last {
  @apply border-r-[0px]
}

tbody {
  @apply bg-white text-[14px] text-center align-top break-keep
}

tr {
  @apply border-b-[1px] border-[#b7b7b7] h-[40px]
}

td {
  @apply border-r-[1px] border-[#b7b7b7] p-[15px]
}

td.last {
  @apply text-left border-r-[0px]
}

.page-item {
  @apply inline-block tracking-tighter min-w-[29px] h-[29px] px-[3px] text-center border-solid border-[1px] border-[#d4d4d4] bg-[white] text-[#333] leading-[29px] align-middle no-underline mx-[3px]
}

.page-item.active {
  @apply bg-[#fa6000] text-[white] border-[0px] leading-[30px]
}

.button-item {
  @apply inline-block tracking-tighter text-[17px] text-[#7d7d7d] font-[600] rounded-r-full rounded-l-full min-w-[86px] border-[1px] self-center min-h-[33px] mx-[5px] border-[#cfcfcf]
}

.button-item.active {
  @apply bg-[#fa6000] text-[white] border-[0px] leading-[30px]
}